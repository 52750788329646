import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "status": "draft",
  "path": "/app-with-database-setup/",
  "heroImage": "woman-using-app.jpg",
  "title": "App With Database Setup",
  "description": "Starter Template for App Connected to Database",
  "date": "2021-11-13T00:00:00.000Z",
  "tags": ["code", "database"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Sidenote = makeShortcode("Sidenote");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`If you like building random apps for weird passion projects or even looking for a production starter template for a real company then you're gonna wanna follow along and build the framework for it.`}</p>
    <h3>{`Technologies we'll use:`}</h3>
    <ul>
      <li parentName="ul">{`Next.js`}</li>
      <li parentName="ul">{`Typescript`}</li>
      <li parentName="ul">{`Prisma`}</li>
      <li parentName="ul">{`Chakra UI`}</li>
      <li parentName="ul">{`Heroku`}</li>
      <li parentName="ul">{`SendGrid`}</li>
      <li parentName="ul">{`Next-Auth`}</li>
    </ul>
    <h2>{`Getting Setup`}</h2>
    <p>{`First, let's create a new Next.js app with Typescript:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`npx create-next-app@latest --typescript
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# or`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`yarn`}</span>{` create next-app --typescript`}</code></pre></div>
    <p>{`Then move into the directory just created:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`cd`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`name of directory`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span></code></pre></div>
    <p>{`And then run:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`npm`}</span>{` run dev
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# or`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`yarn`}</span>{` dev`}</code></pre></div>
    <p>{`Open server started at: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://localhost:3000`}</code></p>
    <h3>{`Front End Component Library`}</h3>
    <p>{`Next, let's add the dependencies we'll need for this app. I have been really liking Chakra UI lately to build the front-end user interface of my apps and websites:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`npm`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` @chakra-ui/react @emotion/react@^11 @emotion/styled@^11 framer-motion@^5
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# or`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`yarn`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`add`}</span>{` @chakra-ui/react @emotion/react@^11 @emotion/styled@^11 framer-motion@^5`}</code></pre></div>
    <p>{`With Chakra installed, we need to first wrap the app with the Chakra Provider. In the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`_app.tsx`}</code>{` file, let's replace the file with the following code:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "tsx"
    }}><pre parentName="div" {...{
        "className": "language-tsx"
      }}><code parentName="pre" {...{
          "className": "language-tsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` AppProps `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"next/app"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` ChakraProvider `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"@chakra-ui/react"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`MyApp`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` Component`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` pageProps `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` AppProps`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`ChakraProvider`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`resetCSS`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token boolean"
              }}>{`true`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
      `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Component`}</span></span>{` `}<span parentName="span" {...{
              "className": "token spread"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`...`}</span>{`pageProps`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
    `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`ChakraProvider`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`default`}</span>{` MyApp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Now we can replace the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`index.tsx`}</code>{` file with the following:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "tsx"
    }}><pre parentName="div" {...{
        "className": "language-tsx"
      }}><code parentName="pre" {...{
          "className": "language-tsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` NextPage `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"next"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` Head `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"next/head"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` Box`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` Container`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` Heading `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"@chakra-ui/react"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` Home`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`NextPage`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Container`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`padding`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token number"
              }}>{`5`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
      `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Head`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
        `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`title`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`Appointment Scheduling App`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`title`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
        `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`meta`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`description`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`content`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`Generated by create next app`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
        `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`link`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`rel`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`icon`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`href`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`/favicon.ico`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
      `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Head`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`

      `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Heading`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`Hello World`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Heading`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
    `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Container`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`default`}</span>{` Home`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h3>{`Database`}</h3>
    <p>{`We will need a database to read and write from. For this starter app, we will be using Prisma, which is an Object-Relational Mapping (ORM) framework, to write to a PostgresSQL database that we'll host on Heroku.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`npm`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` prisma --save-dev
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# or`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`yarn`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`add`}</span>{` prisma --dev`}</code></pre></div>
    <p>{`Now we can create our Prisma schema file template with the following command:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`npx prisma init`}</code></pre></div>
    <p>{`Our Prisma schema was created at prisma/schema.prisma and will look like the following.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "powerquery"
    }}><pre parentName="div" {...{
        "className": "language-powerquery"
      }}><code parentName="pre" {...{
          "className": "language-powerquery"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// schema.prisma`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// This is your Prisma schema file,`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// learn more about it in the docs: https://pris.ly/d/prisma-schema`}</span>{`

generator client `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  provider `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"prisma-client-js"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

datasource db `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  provider `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"postgresql"`}</span>{`
  url      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`env`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"DATABASE_URL"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Now that we Prisma installed, we need to create an actual database. We can create a PostgresSQL database on Heroku.`}</p>
    <Sidenote type="tip" color="var(--color-tip)" background="var(--color-tip-background)" title="Create a Database on Heroku" content="<div>Follow the steps in this article on <a href='https://dev.to/prisma/how-to-setup-a-free-postgresql-database-on-heroku-1dc1' target='_blank'><strong><i>How to set up a free PostgreSQL database on Heroku</i></strong></a></div>" mdxType="Sidenote" />
    <p>{`After creating a PostgresSQL database, we can add the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`DATABASE_URL`}</code>{` to our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.env`}</code>{` file so that this doesn't get uploaded to our remote repository.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "powerquery"
    }}><pre parentName="div" {...{
        "className": "language-powerquery"
      }}><code parentName="pre" {...{
          "className": "language-powerquery"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// .env`}</span>{`

DATABASE_URL`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"postgres://szxdkblujfbxea:2fdf7f81ae35802c0610ef826243ac76355e522fa7283332710960b327b6ac00@ec2-23-20-124-77.compute-1.amazonaws.com:5432/d2psigcigocgnp"`}</span>{`
`}</code></pre></div>
    <p>{`Let's create the schema for our scheduling app. In your `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`schema.prisma`}</code>{` file, update it to the following:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "powerquery"
    }}><pre parentName="div" {...{
        "className": "language-powerquery"
      }}><code parentName="pre" {...{
          "className": "language-powerquery"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// schema.prisma`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// This is your Prisma schema file,`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// learn more about it in the docs: https://pris.ly/d/prisma-schema`}</span>{`

generator client `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  provider `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"prisma-client-js"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

datasource db `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  provider `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"postgresql"`}</span>{`
  url      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`env`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"DATABASE_URL"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

model Appointment `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  id        Int     `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`@`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`default`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`autoincrement`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`@`}</span>{`id
  `}<span parentName="code" {...{
            "className": "token data-type variable"
          }}>{`date`}</span>{`      String
  notes     String`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{`
  status    Boolean `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`@`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`default`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  createdAt DateTime  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`@`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`default`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`now`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`@`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`name: `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"created_at"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  updatedAt DateTime  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`@`}</span>{`updatedAt `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`@`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`name: `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"updated_at"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  doctor    User`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{`   `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`@`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`relation`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`fields: `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`doctorId`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` references: `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  doctorId  Int`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

model User `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  id            Int       `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`@`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`default`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`autoincrement`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`@`}</span>{`id
  name          String`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{`
  email         String`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{`   `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`@`}</span>{`unique
  createdAt     DateTime  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`@`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`default`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`now`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`@`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`name: `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"created_at"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  updatedAt     DateTime  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`@`}</span>{`updatedAt `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`@`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`name: `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"updated_at"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  appointments  Appointment`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`@`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`@`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`name: `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"users"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`We now have a working database and have updated our schema so let's create the tables in our database.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`npx prisma db push`}</code></pre></div>
    <p>{`If you see the following message, then congratulations your database is in sync!`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`🚀  Your database is now `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sync`}</span>{` with your schema. Done `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span>{`.61s`}</code></pre></div>
    <p>{`Now that we have a working database, we'll need to install the Prisma Client in order to access our database from our app. We can install it with the following.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`npm`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` @prisma/client
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# or`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`yarn`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`add`}</span>{` @prisma/client`}</code></pre></div>
    <Sidenote type="warning" color="var(--color-warning)" background="var(--color-warning-background)" title="Warning" content="Because Prisma Client is tailored to your own schema, you need to update it every time your Prisma schema file changes by running the following command:" mdxType="Sidenote" />
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`npx prisma generate`}</code></pre></div>
    <p>{`You'll use a single PrismaClient instance that you can import into any file where it's needed. The instance will be created in a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`prisma.ts`}</code>{` file inside the lib/ directory. Go ahead and create the missing directory and file:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` lib `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`touch`}</span>{` lib/prisma.ts`}</code></pre></div>
    <p>{`Now copy and paste the following into the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`lib/prisma.ts`}</code>{` file:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// lib/prisma.ts`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` PrismaClient `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"@prisma/client"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`let`}</span>{` prisma`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` PrismaClient`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`process`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`env`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`NODE_ENV`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"production"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  prisma `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`PrismaClient`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`global`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`prisma`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    global`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`prisma `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`PrismaClient`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  prisma `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` global`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`prisma`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`default`}</span>{` prisma`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <Sidenote type="warning" color="var(--color-warning)" background="var(--color-warning-background)" title="Warning" content="If you receive the error <strong>Element implicitly has an 'any' type because type 'typeof globalThis' has no index signature.</strong> then you can add <strong>'noImplicitAny': false,</strong> to your tsconfig.json file" mdxType="Sidenote" />
    <h3>{`Authentication Provider`}</h3>
    <p>{`We don't just want anyone to be able to access our app. Let's use NextAuth.js to add authentication to this app.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`npm`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` next-auth
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# or`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`yarn`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`add`}</span>{` next-auth`}</code></pre></div>
    <p>{`Let's add the types for NextAuth.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`npm`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` @types/next-auth --save-dev
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# or`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`yarn`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`add`}</span>{` @types/next-auth --dev`}</code></pre></div>
    <h1>{`Heading 1`}</h1>
    <p>{`Let's put some happy little clouds in our world. Maybe there's a little something happening right here. Isn't it fantastic that you can change your mind and create all these happy things? You don't want to kill all your dark areas they are very important.`}</p>
    <h2>{`Heading 2`}</h2>
    <p>{`Let's put some happy little clouds in our world. Maybe there's a little something happening right here. Isn't it fantastic that you can change your mind and create all these happy things? You don't want to kill all your dark areas they are very important.`}</p>
    <h3>{`Heading 3`}</h3>
    <p>{`Let's put some happy little clouds in our world. Maybe there's a little something happening right here. Isn't it fantastic that you can change your mind and create all these happy things? You don't want to kill all your dark areas they are very important.`}</p>
    <h4>{`Heading 4`}</h4>
    <p>{`Let's put some happy little clouds in our world. Maybe there's a little something happening right here. Isn't it fantastic that you can change your mind and create all these happy things? You don't want to kill all your dark areas they are very important.`}</p>
    <h5>{`Heading 5`}</h5>
    <p>{`Let's put some happy little clouds in our world. Maybe there's a little something happening right here. Isn't it fantastic that you can change your mind and create all these happy things? You don't want to kill all your dark areas they are very important.`}</p>
    <h6>{`Heading 6`}</h6>
    <p>{`Let's put some happy little clouds in our world. Maybe there's a little something happening right here. Isn't it fantastic that you can change your mind and create all these happy things? You don't want to kill all your dark areas they are very important.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      